<template>
  <div class="programme py-3">
    <div class="close-back">
      <router-link to="/lobby">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#fff"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-x"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </router-link>
    </div>
    <header>
      <div class="container-fluid">
        <div class="row align-items-center justify-content-between">
          <div class="col-sm-6">
            <img
              src="@/assets/img/programme/header.png"
              class="img-fluid"
              alt="header text"
            />
          </div>
          <div class="col-sm-4 text-center">
            <img
              src="@/assets/img/sateclogo.png"
              class="img-fluid w-50"
              alt="logo"
            />
          </div>
        </div>
      </div>
      <div class="row no-gutters heading-strip my-4">
        <div class="col-sm-12 px-3 py-2">
          <h1>Programme</h1>
        </div>
      </div>
    </header>
    <div class="container-fluid">
      <ul id="pills-tab" role="tablist">
        <li class="d-flex nav-item">
          <a
            id="pills-cam-tab"
            data-toggle="tab"
            href="#pills-cam"
            role="tab"
            aria-controls="pills-cam"
            aria-selected="true"
            class="btn info-btn round-btn px-5 py-2 nav-link"
            >Conference (AM)</a
          >
        </li>

        <div class="tabs-row my-4 px-0">
          <li class="text-center mb-3 mr-2 nav-item">
            <p class="mb-1">breakout room 1</p>
            <a
              class="btn dark-btn round-btn nav-link active py-2"
              id="pills-br1-tab"
              data-toggle="tab"
              href="#pills-br1"
              role="tab"
              aria-controls="pills-br1"
              aria-selected="true"
            >
              MAINTENANCE OF THE FUTURE
            </a>
          </li>
          <li class="text-center mb-3 mr-2 nav-item">
            <p class="mb-1">breakout room 2</p>
            <a
              class="btn dark-btn round-btn nav-link py-2"
              id="pills-br2-tab"
              data-toggle="tab"
              href="#pills-br2"
              role="tab"
              aria-controls="pills-br2"
              aria-selected="false"
              >Green Technologies</a
            >
          </li>
          <li class="text-center mb-3 mr-2 nav-item">
            <p class="mb-1">breakout room 3</p>
            <a
              class="btn dark-btn round-btn nav-link py-2"
              id="pills-br3-tab"
              data-toggle="tab"
              href="#pills-br3"
              role="tab"
              aria-controls="pills-br3"
              aria-selected="false"
              >4IR IN AEROSPACE</a
            >
          </li>
          <li class="text-center mb-3 mr-2 nav-item">
            <p class="mb-1">breakout room 4</p>
            <a
              class="btn dark-btn round-btn nav-link py-2"
              id="pills-br4-tab"
              data-toggle="tab"
              href="#pills-br4"
              role="tab"
              aria-controls="pills-br4"
              aria-selected="false"
            >
              ADVANCED AEROSPACE TOPICS
            </a>
          </li>
          <li class="text-center mb-3 mr-2">
            <p class="invisible mb-1">asda</p>
            <a
              href="/pdf/downloadable.pdf"
              download
              @click="logActivity('programme download', 'download')"
              class="btn dark-btn round-btn download py-2"
            >
              Download Programme
            </a>
          </li>
        </div>
      </ul>

      <div class="row">
        <div class="col-sm-12">
          <div class="tab-content">
            <div
              class="tab-pane fade"
              id="pills-cam"
              role="tabpanel"
              aria-labelledby="pills-cam-tab"
            >
              <img
                src="@/assets/img/programme/cam.png"
                class="img-fluid"
                alt="programme"
                v-if="isUADesktop"
              />
              <img
                src="@/assets/img/programme/cam.png"
                class="img-fluid"
                alt="programme"
                v-if="isUAMobile || isUATablet"
              />
            </div>
            <div
              class="tab-pane fade show active"
              id="pills-br1"
              role="tabpanel"
              aria-labelledby="pills-br1-tab"
            >
              <img
                src="@/assets/img/programme/br1.png"
                class="img-fluid"
                alt="programme"
                v-if="isUADesktop"
              />
              <img
                src="@/assets/img/programme/m1.png"
                class="img-fluid"
                alt="programme"
                v-if="isUAMobile || isUATablet"
              />
            </div>
            <div
              class="tab-pane fade"
              id="pills-br2"
              role="tabpanel"
              aria-labelledby="pills-br2-tab"
            >
              <img
                src="@/assets/img/programme/br2.png"
                class="img-fluid"
                alt="programme"
                v-if="isUADesktop"
              />
              <img
                src="@/assets/img/programme/m2.png"
                class="img-fluid"
                alt="programme"
                v-if="isUAMobile || isUATablet"
              />
            </div>
            <div
              class="tab-pane fade"
              id="pills-br3"
              role="tabpanel"
              aria-labelledby="pills-br3-tab"
            >
              <img
                src="@/assets/img/programme/br3.png"
                class="img-fluid"
                alt="programme"
                v-if="isUADesktop"
              />
              <img
                src="@/assets/img/programme/m3.png"
                class="img-fluid"
                alt="programme"
                v-if="isUAMobile || isUATablet"
              />
            </div>
            <div
              class="tab-pane fade"
              id="pills-br4"
              role="tabpanel"
              aria-labelledby="pills-br4-tab"
            >
              <img
                src="@/assets/img/programme/br4.png"
                class="img-fluid"
                alt="programme"
                v-if="isUADesktop"
              />
              <img
                src="@/assets/img/programme/m4.png"
                class="img-fluid"
                alt="programme"
                v-if="isUAMobile || isUATablet"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import UAParser from "ua-parser-js";
import util from "@/assets/js/util";

export default {
  name: "programme",
  data() {
    return {
      isUADesktop: false,
      isUAMobile: false,
      isUATablet: false,
    };
  },
  methods: {
    logActivity(event, type) {
      util.auth_middleware(this, event, type);
    },
  },
  mounted() {
    let parser = new UAParser();

    let parser_result = parser.getResult();

    if (parser_result.device.type == "mobile") {
      this.isUAMobile = true;
    } else if (parser_result.device.type == "tablet") {
      this.isUATablet = true;
    } else {
      this.isUADesktop = true;
    }

    $("#pills-tab li.nav-item a.nav-link").click(function () {
      $("#pills-tab li.nav-item a.nav-link").removeClass("active");
    });
  },
};
</script>

<style lang="scss" scoped>
.programme {
  background: url(../assets/img/programme/bg.jpg) no-repeat;
  background-position: top;
  background-size: cover;
  min-height: 100vh;
  position: relative;
  & .heading-strip {
    & h1 {
      color: #fff;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 2rem;
      margin-bottom: 0;
    }
    & div {
      background-color: $medium-blue;
    }
  }
  & .info-btn {
    background-color: $info;
    border-color: $info;
    transition: 0.3s;
    font-weight: 700;
    font-size: 1.1rem;
    text-transform: uppercase;
    &:hover {
      background-color: $dark-blue;
      border-color: $dark-blue;
      color: #fff;
    }
  }
  & .dark-btn {
    background-color: $dark-blue;
    border-color: $dark-blue;
    transition: 0.3s;
    font-weight: 700;
    font-size: 1.1rem;
    text-transform: uppercase;
    &:hover {
      background-color: $info;
      border-color: $info;
      color: #fff;
    }
  }
  ul {
    list-style: none;
  }
  & .tabs-row {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    & li {
      flex: 0 0 auto;
    }
    & p {
      color: #fff;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.2rem;
    }
    & .download {
      background-color: $medium-blue;
      border-color: $medium-blue;
    }
    .active {
      background-color: $info;
      border-color: $info;
    }
  }
}
</style>
